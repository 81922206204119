<script setup>
import {computed, ref} from 'vue';
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import AppLayout from "@/Layouts/AppLayout.vue";
import Map from "@/Components/Map.vue";
import ContactForm from "@/Components/ContactForm.vue";
import Breadcrumb from "@/Components/Breadcrumb.vue";
import Button from "@/Components/Button.vue";
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";

const { state } = useStore();
const locale = computed(() => useI18n().locale);
const {t} = useI18n();

const breadcrumbs = computed(() => [
    {
        title: t('titleHome'),
        disabled: false,
        href: state.links?.home?.[locale.value.value],
    },
    {
        title: t('titleContacts'),
        disabled: true,
        href: '/',
    },
]);

const callbackFormRef = ref(null);
const scrollToCallbackForm = () => {
    if (callbackFormRef.value) {
        const offset = 100;
        const elementPosition = callbackFormRef.value.getBoundingClientRect().top + window.scrollY;
        const scrollPosition = elementPosition - offset;

        window.scrollTo({top: scrollPosition, behavior: 'smooth'});
    }
}

const coordinatesUrl = ref(
    state?.settings?.coordinates ?
        `https://www.google.com/maps/search/?api=1&query=${state?.settings?.coordinates?.latitude},${state?.settings?.coordinates?.longitude}` :
        '#'
);

const officeAddress = computed(() => {
    return `${locale.value.value}` === 'ru' ?
        state?.settings?.office_address_ru : state?.settings?.office_address
});
</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <section class="bg-neutral-100 font-philosopher text-center">
            <h1 class="text-black text-5xl md:text-7x leading-normal">{{ t('connectWithUs') }}</h1>
        </section>

        <NovaComponentsLoader/>

        <section class="bg-neutral-100">
            <div class="flex justify-center">
                <Button
                    kind="big"
                    @click="scrollToCallbackForm"
                >
                    {{ t('orderCallback') }}
                </Button>
            </div>

            <div
                class="
                    text-black text-base sm:text-xl  lg:text-left
                    flex items-center lg:justify-between flex-col lg:flex-row gap-10 xl:gap-24
                    py-10 sm:py-16 lg:py-24 px-5 xl:px-0 w-full xl:w-2/3 mx-auto
                "
            >
                <div
                    v-if="state?.settings?.main_phone_number || state?.settings?.main_email"
                    class="text-center lg:text-left"
                >
                    <h3 class="uppercase mb-8 font-semibold">{{ t('titleContacts') }}</h3>
                    <a
                        :href="`tel:${state?.settings?.main_phone_number}`"
                        class="hover:text-green"
                    >
                        {{ state?.settings?.main_phone_number }}
                    </a>
                    <br>
                    <a
                        :href="`mailto:${state?.settings?.main_email}`"
                        class="hover:text-green"
                    >
                        {{ state?.settings?.main_email || 'info@fdoor.com.ua' }}
                    </a>
                </div>
                <div class="text-center lg:text-left">
                    <h3 class="uppercase mb-8 font-semibold">{{ t('address') }}</h3>
                    <a
                        :href="coordinatesUrl"
                        :target="coordinatesUrl === '#' ? '_self' : '_blank'"
                        class="inline-block w-2/3 lg:w-auto hover:text-green"
                    >
                        <span v-html="officeAddress"></span>
                    </a>
                </div>
                <div class="text-center lg:text-left">
                    <h3 class="uppercase mb-8 font-semibold">{{ t('workingText') }}</h3>
                    <span class="whitespace-nowrap">
                        {{ t('workingHoursText1') }}<br>{{ t('workingHoursText2') }}
                    </span>
                </div>
            </div>
        </section>

        <Map :property="state?.settings?.coordinates" />

        <section
            id="contacts-block"
            ref="callbackFormRef"
            class="bg-neutral-100 pt-10 sm:pt-16 lg:pt-24 pb-9"
        >
            <ContactForm
                class="px-5 md:px-0 w-full md:w-1/2 lg:w-2/5 mx-auto"
                :has-select="true"
                endpoint="/contact"
            />
        </section>

        <section class="bg-neutral-100 text-center px-5 py-10 sm:py-16 lg:py-24">
            <p class="text-green text-2xl md:text-4xl font-normal font-philosopher w-full md:w-1/2 lg:w-2/5 mx-auto">
                {{ t('contactsPageContactBlockText') }}
            </p>
        </section>
    </AppLayout>
</template>
