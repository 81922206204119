export default {
    fullName: 'ФИО',
    name: "Имя",
    phone: 'Телефон',
    email: 'Email',
    topic: 'Выберите тему',
    addressDelivery: 'адресная доставка Новой почтой',
    pickup: 'самовывоз из Новой Почты',
    courierDelivery: 'курьерная доставка',
    warehousePickup: 'самовывоз из нашего склада',
    bankAccount: 'на расчетный счет',
    card: 'перевод на карту',
    necessarily: 'обязательно',
    receiver: 'Получатель',
    delivery: 'Доставка',
    deliveryType: 'Способ доставки',
    carrierTariffs: 'По тарифам перевозчика',
    negotiable: 'Договорная',
    free: 'Бесплатно',
    paymentMethod: 'Способ оплаты',
    edit: 'Редактировать',
    earlyTotal: 'Предварительная сумма',
    confirmOrder: 'Подтвердить заказ',
    receiptWithPrepayment: 'при получении с подпиской',
    workingText: 'Работаем',
    workingHoursText1: 'пн-пт (9:00-18:00)',
    workingHoursText2: 'сб-вс (отдыхаем)',
    titleHome: 'Главная',
    titleAbout: 'О нас',
    titleCart: 'Корзина',
    titleCheckout: 'Оформление заказа',
    titleContacts: 'Контакты',
    titlePartnership: 'Партнерство',
    titlePrivacyPolicy: 'Политика конфиденциальности',
    titleUserAgreement: 'Пользовательское соглашение',
    partitionGlassInteriorTitle: 'Стеклянные межкомнатные перегородки',
    homeSubtitle1: 'F’DOOR сочетает минималистичный',
    homeSubtitle2: 'дизайн с отличным мастерством',
    homeSubtitle3: 'создавая двери и перегородки',
    homeSubtitle4: 'в современном стиле.',
    handle: 'Дверная ручка',
    handleType: 'Тип дверной ручки',
    handleCoverWC: 'Накладка под WC',
    cylinderCover: 'Накладка под цилиндр',
    choseOtherHandle: 'Выбрать другую ручку',
    doorDimensions: 'Размеры полотна',
    connectWithManager: 'Связаться с менеджером',
    addToCart: 'Добавить в корзину',
    dimensionHW: 'Размер В*Ш',
    profileColor: 'Цвет профиля',
    opening: 'Открывание',
    complexion: 'Комплектация',
    brand: 'Бренд',
    covering: 'Покрытие',
    pcs: 'шт',
    perSet: 'за комплект',
    delete: 'Удалить',
    earlySetPrice: 'Предварительная цена комплекта',
    contact: 'Контакт',
    sendRequest: 'Отправить запрос',
    addedToYourCard: 'Добавлено в вашу корзину',
    seeCard: 'Посмотреть корзину',
    goToCheckout: 'Перейти к оформлению',
    seeAllDoorHandlesCollection: 'Посмотреть всю коллекцию дверных ручек',
    yourOrder: 'Ваш запрос',
    yourOrderSendToManagerText: 'передан в работу менеджеру. С вами свяжутся в ближайшее время для уточнения информации.',
    thank: 'Благодарим',
    catalog: 'Каталог',
    information: 'Информация',
    userAgreement: 'Соглашение пользователя',
    privacyPolicy: 'Политика конфиденциальности',
    doorsDefault: 'Двери скрытого монтажа',
    partitions: 'Перегородки',
    continueShopping: 'Продолжить покупки',
    goToShopping: 'Перейти к покупкам',
    yourCartIsEmpty: 'Ваша корзина пуста',
    connectWithUs: 'Свяжитесь с нами',
    orderCallback: 'Заказать обратный звонок',
    address: 'Адрес',
    yourMessages: 'Ваши сообщения',
    contactsPageContactBlockText: 'Готовы реализовать свои уникальные задумки? Наши менеджеры помогут вам сделать лучший выбор учитывая ваши идеи, функциональные потребности и бюджет.',
    nonStandardDimensions1: 'Если вам необходимы',
    nonStandardDimensions2: 'нестандартные размеры',
    nonStandardDimensions3: ', свяжитесь с менеджером для просчета',
    art: 'арт.',
    advantagesBlockKey11: 'Реализация',
    advantagesBlockKey12: '“под ключ”',
    advantagesBlockKey21: 'Профессиональная',
    advantagesBlockKey22: 'поддержка',
    advantagesBlockKey31: 'Библиотека',
    advantagesBlockKey32: 'образцов',
    advantagesBlockKey41: 'Персональные',
    advantagesBlockKey42: 'условия',
    pageNotFound: 'Страница не найдена',
    'doors-product' : "Двери",
    'doors-custom-product': "Двери на заказ",
    'accessories': "Аксессуары"
}
