<script setup>
import { computed, onMounted, onBeforeUnmount, reactive, ref } from 'vue';
import { onClickOutside, useToggle } from '@vueuse/core';
import { initCollapses, initDropdowns } from 'flowbite';
import { Link, router } from '@inertiajs/vue3';
import emitter from "@/plugins/eventBus.js";
import ButtonCmp from '@/Components/Button.vue';
import ButtonsGroup from '@/Components/ButtonsGroup.vue';
import BurgerButton from '@/Components/BurgerButton.vue';
import Basket from '@/Components/Basket.vue';
// import Search from '@/Components/Search.vue';
import MegaMenu from '@/Components/MegaMenu.vue';
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const {state} = useStore();

const [isSearchInputVisible] = useToggle(false);

// languages
const locale = computed(() => useI18n().locale);

const languageBtnRef = ref(null);
const languages = {
    uk: 'укр',
    ru: 'рус'
}

const language = reactive({
    chosen: locale.value,
    available: ['uk', 'ru']
});

const available = computed(() => language.available.find(lg => lg !== language.chosen));

const [isLanguageVisible, toggleLanguageBtn] = useToggle(false);

const closeLanguageBtn = () => isLanguageVisible.value = false;
onClickOutside(languageBtnRef, closeLanguageBtn);

// mega menu
const doorMegaMenuItems = [
    {
        linkSrc: state?.links?.['doors-product'],
        items: state?.productsMenu?.['doors-product'],
    },
    {
        linkSrc: state?.links?.['doors-custom-product'],
        items: state?.productsMenu?.['doors-custom-product'],
    },
]

const partitionsMegaMenuItems = computed(() => {
    if (!Object.values(state?.links?.['partitions-category']?.categories).length) {
        return [];
    }

    return Object.values(state?.links?.['partitions-category']?.categories).map(category => ({
        linkSrc: category,
        items: { image: category.image, title: category.title, blocks: category.blocks },
    })).slice(0, 2);
});

const currentMenuType = ref('');
const currentMenuItems = ref(doorMegaMenuItems);
const isMegaMenuOpen = ref(false);
const megaMenuRef = ref(null);
const megaMenuBtnDoorRef = ref(null);

const showMegaMenu = menuType => {
    if (currentMenuType.value !== menuType) {
        currentMenuItems.value = menuType === 'door' ? doorMegaMenuItems : partitionsMegaMenuItems.value;
        currentMenuType.value = menuType;
        isMegaMenuOpen.value = true;
    } else {
        isMegaMenuOpen.value = !isMegaMenuOpen.value;
    }
}
const closeMegaMenu = () => isMegaMenuOpen.value = false;

onClickOutside(megaMenuRef, closeMegaMenu, { ignore: [megaMenuBtnDoorRef] },);

// burger menu
const burgerMenuIsOpen = ref(false);

const toggleBurgerMenu = () => {
    burgerMenuIsOpen.value = !burgerMenuIsOpen.value;

    document.body.classList.toggle('overflow-hidden', burgerMenuIsOpen.value);
}

const onNavigateTo = (link, componentId) => {
    if (window.location.href.includes(link)) {
        toggleBurgerMenu();
        emitter.$emit(componentId);
        return;
    }

    router.visit(link, {
        onFinish: () => {
          emitter.$emit(componentId);
          document.body.classList.contains('overflow-hidden') && toggleBurgerMenu();
        }
    })
}

onMounted(() => {
    emitter.$on('closeMegaMenu', closeMegaMenu)
    initCollapses();
    initDropdowns()
})
onBeforeUnmount(() => emitter.$off('closeMegaMenu'));
</script>

<template>
    <header
        class="fixed top-0 right-0 left-0 z-20 lg:h-auto md:mx-auto"
        :class="{ 'bottom-0 header-padding': burgerMenuIsOpen }"
    >
        <nav
            :class="{ 'h-full': burgerMenuIsOpen, 'h-fit': !burgerMenuIsOpen }"
            class="bg-white border-gray-200 lg:h-[90px]"
        >
            <div class="flex flex-col h-full lg:h-auto lg:flex-row justify-between items-center mx-auto  font-semibold p-5 lg:p-4 xl:px-[3.65%] md:max-w-[1920px]">
                <div class="w-full lg:flex align-center gap-10">
                    <div class="lg:flex order-1 lg:order-none items-center justify-between w-full whitespace-nowrap lg:w-1/2">
                        <ul class="hidden lg:flex flex-col items-center font-medium lg:flex-row space-x-4 xl:space-x-10 rtl:space-x-reverse">
                            <li ref="megaMenuBtnDoorRef">
                                <button
                                    class="flex text-xxs text-black uppercase font-semibold hover:text-green items-center justify-between px-3 w-auto md:p-0 dark:text-white"
                                    @click="showMegaMenu('door')"
                                >
                                    {{ $t('doorsDefault') }}
                                    <svg
                                        class="w-2.5 h-2.5 ms-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 10 6"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="m1 1 4 4 4-4"
                                        />
                                    </svg>
                                </button>
                            </li>
                            <li v-if="partitionsMegaMenuItems.length && false">
                                <button
                                    class="flex text-xxs text-black uppercase font-semibold hover:text-green items-center justify-between px-3 w-auto md:p-0 dark:text-white"
                                    @click="showMegaMenu('partitions')"
                                >
                                    {{ $t('partitions') }}
                                    <svg
                                        class="w-2.5 h-2.5 ms-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 10 6"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="m1 1 4 4 4-4"
                                        />
                                    </svg>
                                </button>
                            </li>
                        </ul>

                        <div class="flex w-full lg:justify-end">
                            <BurgerButton
                                :burger-menu-is-open="burgerMenuIsOpen"
                                @click="toggleBurgerMenu"
                            />

                            <Link
                                :href="state.links?.home?.[locale.value] || '/'"
                                class="flex items-center mx-auto lg:mx-0 space-x-3 rtl:space-x-reverse lg:h-[56.5px]"
                                :class="{ 'hidden': burgerMenuIsOpen }"
                                aria-current="page"
                            >
                                <svg
                                    class="w-28 h-8 xl:w-auto xl:h-auto mx-auto"
                                    width="151"
                                    height="42"
                                    viewBox="0 0 151 42"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_325_15504)">
                                        <g clip-path="url(#clip1_325_15504)">
                                            <path
                                                d="M86.3583 15.7554C86.3583 19.2797 85.2188 22.1819 82.9398 24.4623C80.6608 26.7427 77.8638 27.8829 74.3417 27.8829H67.7119C64.2934 27.8829 61.3928 26.7427 59.1138 24.4623C56.8348 22.1819 55.6953 19.2797 55.6953 15.7554C55.6953 12.2312 56.8348 9.43257 59.0102 7.3595C61.3928 5.18278 64.2934 4.14624 67.7119 4.14624H74.3417C77.9674 4.14624 80.7644 5.18278 83.0434 7.25585C85.2188 9.43257 86.3583 12.2312 86.3583 15.7554ZM82.2147 16.0664C82.2147 13.786 81.4895 11.9203 80.0392 10.4691C78.589 9.01796 76.7243 8.29238 74.4453 8.29238H67.8155C65.5365 8.29238 63.6718 9.01796 62.2216 10.4691C60.7713 11.9203 60.0461 13.786 60.0461 16.0664C60.0461 18.3468 60.7713 20.2125 62.2216 21.56C63.6718 23.0112 65.5365 23.6331 67.8155 23.6331H74.4453C76.7243 23.6331 78.589 22.9075 80.0392 21.56C81.4895 20.2125 82.2147 18.3468 82.2147 16.0664Z"
                                                fill="#38383B"
                                            />
                                            <path
                                                d="M119.714 15.7554C119.714 19.2797 118.574 22.1819 116.295 24.4623C114.016 26.7427 111.219 27.8829 107.697 27.8829H101.067C97.6488 27.8829 94.7483 26.7427 92.4693 24.4623C90.1903 22.1819 89.0508 19.2797 89.0508 15.7554C89.0508 12.2312 90.1903 9.43257 92.3657 7.3595C94.7483 5.18278 97.6488 4.14624 101.171 4.14624H107.801C111.323 4.14624 114.223 5.18278 116.399 7.3595C118.574 9.43257 119.714 12.2312 119.714 15.7554ZM115.674 16.0664C115.674 13.786 114.949 11.9203 113.498 10.4691C112.048 9.01796 110.183 8.29238 107.904 8.29238H101.275C98.9955 8.29238 97.1309 9.01796 95.6806 10.4691C94.2303 11.9203 93.5052 13.786 93.5052 16.0664C93.5052 18.3468 94.2303 20.2125 95.6806 21.56C97.1309 23.0112 98.9955 23.6331 101.275 23.6331H107.904C110.183 23.6331 112.048 22.9075 113.498 21.56C114.845 20.2125 115.674 18.3468 115.674 16.0664Z"
                                                fill="#38383B"
                                            />
                                            <path
                                                d="M143.746 20.7308C145.714 20.2125 147.268 19.2797 148.511 17.9322C149.754 16.481 150.376 14.8226 150.376 12.7495C150.376 9.84718 149.34 7.56681 147.164 6.11566C145.3 4.76816 142.813 4.14624 139.809 4.14624H122.82V27.8829H126.964V21.2491H138.359L144.989 27.8829H151.101L143.746 20.7308ZM126.86 17.1029V8.29238H140.431C144.16 8.29238 146.025 9.74353 146.025 12.7495C146.025 14.2006 145.403 15.3408 144.264 16.17C143.228 16.792 141.985 17.2066 140.431 17.2066H126.86V17.1029Z"
                                                fill="#38383B"
                                            />
                                            <path
                                                d="M49.686 7.25585C47.407 5.18278 44.5065 4.14624 41.0879 4.14624H31.7647V8.29238H41.0879C43.3669 8.29238 45.2316 9.01796 46.6819 10.4691C48.1321 11.9203 48.8573 13.786 48.8573 16.0664C48.8573 18.3468 48.1321 20.2125 46.6819 21.56C45.2316 23.0112 43.3669 23.6331 41.0879 23.6331H31.7647V8.29238H27.6211V27.8829H40.9844C44.5065 27.8829 47.3034 26.7427 49.5824 24.4623C51.8614 22.1819 53.0009 19.2797 53.0009 15.7554C53.0009 12.2312 51.8614 9.43257 49.686 7.25585Z"
                                                fill="#38383B"
                                            />
                                            <path
                                                d="M28.3481 35.3458V37.2116H30.5235V37.9371H28.3481V41.0467H27.7266V34.7239H30.6271V35.3458H28.3481Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M37.464 39.2848L36.7389 41.0469H35.9102L38.7071 34.6204H39.0179L41.7113 41.0469H40.8825L40.1574 39.2848H37.464ZM38.9143 36.0715L37.7748 38.6628H39.9502L38.9143 36.0715Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M50.8265 35.9678C50.5157 35.4495 50.2049 35.2422 49.687 35.2422C49.169 35.2422 48.6511 35.6568 48.6511 36.2787C48.6511 36.797 49.169 37.1079 49.687 37.3152L50.1014 37.5226C50.9301 37.9372 51.6552 38.3518 51.6552 39.2847C51.6552 40.4249 50.7229 41.2541 49.687 41.2541C48.6511 41.2541 47.9259 40.6322 47.7188 39.5956L48.4439 39.3883C48.5475 40.0102 49.0654 40.5285 49.687 40.5285C50.4121 40.5285 51.0337 40.0102 51.0337 39.2847C51.0337 38.5591 50.4121 38.2481 49.8942 38.0408L49.4798 37.8335C48.7547 37.5226 48.1331 37.1079 48.1331 36.1751C48.1331 35.1385 48.9618 34.5166 49.8942 34.5166C50.6193 34.5166 51.2409 34.9312 51.5516 35.5531L50.8265 35.9678Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M62.1201 37.3152V34.7239H62.8452V41.0467H62.1201V37.9371H58.9087V41.0467H58.1836V34.7239H58.9087V37.3152H62.1201Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M70.4048 34.7239H69.6797V41.1504H70.4048V34.7239Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M83.458 37.8335C83.458 39.6993 81.9041 41.1504 80.143 41.1504C78.2784 41.1504 76.8281 39.6993 76.8281 37.8335C76.8281 35.9678 78.382 34.5166 80.143 34.5166C82.0077 34.6203 83.458 36.0714 83.458 37.8335ZM77.5533 37.8335C77.5533 39.2847 78.6928 40.4249 80.143 40.4249C81.5933 40.4249 82.7328 39.181 82.7328 37.8335C82.7328 36.3824 81.5933 35.2422 80.143 35.2422C78.6928 35.2422 77.5533 36.3824 77.5533 37.8335Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M89.9844 34.7239H90.1916L94.9567 39.5956V34.7239H95.6819V41.0467H95.3711L90.7095 36.175V41.0467H89.9844V34.7239Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M110.496 34.7239H111.739C112.672 34.7239 113.5 34.8275 114.225 35.4495C114.951 36.0714 115.261 36.9006 115.261 37.8335C115.261 38.7664 114.951 39.5956 114.225 40.2175C113.5 40.9431 112.672 41.0467 111.739 41.0467H110.6V34.7239H110.496ZM111.739 40.4248C112.568 40.4248 113.189 40.3212 113.707 39.8029C114.225 39.2846 114.536 38.6627 114.536 37.9371C114.536 37.2116 114.225 36.486 113.707 36.0714C113.086 35.5531 112.464 35.4495 111.739 35.4495H111.221V40.4248H111.739Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M128.106 37.8335C128.106 39.6993 126.553 41.1504 124.791 41.1504C122.927 41.1504 121.477 39.6993 121.477 37.8335C121.477 35.9678 123.03 34.5166 124.791 34.5166C126.553 34.6203 128.106 36.0714 128.106 37.8335ZM122.098 37.8335C122.098 39.2847 123.238 40.4249 124.688 40.4249C126.138 40.4249 127.278 39.181 127.278 37.8335C127.278 36.3824 126.138 35.2422 124.688 35.2422C123.238 35.2422 122.098 36.3824 122.098 37.8335Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M140.849 37.8335C140.849 39.6993 139.295 41.1504 137.534 41.1504C135.669 41.1504 134.219 39.6993 134.219 37.8335C134.219 35.9678 135.773 34.5166 137.534 34.5166C139.295 34.6203 140.849 36.0714 140.849 37.8335ZM134.84 37.8335C134.84 39.2847 135.98 40.4249 137.43 40.4249C138.88 40.4249 140.02 39.181 140.02 37.8335C140.02 36.3824 138.88 35.2422 137.43 35.2422C136.083 35.2422 134.84 36.3824 134.84 37.8335Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M147.995 41.0469H147.27V34.6204H148.202C149.445 34.6204 150.584 34.9313 150.584 36.3825C150.584 37.3153 149.963 38.0409 149.031 38.1446L151.102 41.0469H150.274L148.305 38.2482H148.098V41.0469H147.995ZM147.995 37.6263H148.202C149.031 37.6263 149.859 37.5227 149.859 36.4861C149.859 35.4496 149.134 35.3459 148.202 35.3459H147.995V37.6263Z"
                                                fill="#607B7D"
                                            />
                                            <path
                                                d="M27.7265 8.29238V4.14624H1V27.8829H5.14365V17.8285H23.5829V13.6824H5.14365V8.29238H27.7265Z"
                                                fill="#5A7B7E"
                                            />
                                            <path
                                                d="M31.7647 0H27.6211V4.14614H31.7647V0Z"
                                                fill="#5A7B7E"
                                            />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_325_15504">
                                            <rect
                                                width="150"
                                                height="41.1504"
                                                fill="white"
                                                transform="translate(0.605469 0.424805)"
                                            />
                                        </clipPath>
                                        <clipPath id="clip1_325_15504">
                                            <rect
                                                width="150"
                                                height="41.1504"
                                                fill="white"
                                                transform="translate(1)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>

                            <div
                                v-show="!burgerMenuIsOpen"
                                class="self-center flex lg:hidden"
                            >
                                <a
                                    :href="`tel:${state?.settings?.main_phone_number}`"
                                    class="text-black hover:text-green transition-colors duration-300 pt-0.5 mr-4"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.619176 5.75937C0.414705 8.58106 2.5003 13.5701 6.7124 17.6595C10.9245 21.749 15.9545 23.671 18.7762 23.3847C20.8618 23.4256 22.8247 22.117 23.479 20.0314L23.7652 19.0908C23.9697 18.3956 23.6017 17.7004 22.9065 17.4551L19.1033 16.2691L16.2407 15.3695C15.5455 15.165 14.8503 15.5331 14.605 16.2283L14.3187 17.1688C14.196 17.496 14.1551 17.864 14.1142 18.1912C12.4785 17.7822 10.6791 16.7599 9.08425 15.2059C7.48938 13.6519 6.42614 11.8935 5.9763 10.2577C6.30345 10.2168 6.6715 10.135 6.99865 10.0124L7.93922 9.6852C8.63442 9.43983 8.96157 8.70374 8.7571 8.04943L7.77564 5.18685L6.42614 1.46548C6.18077 0.770285 5.44468 0.443132 4.79037 0.647602L3.84981 0.974756C1.76421 1.66996 0.496493 3.67377 0.619176 5.75937Z"
                                            fill="#38383B"
                                        />
                                    </svg>
                                </a>

                                <Basket />
                            </div>
                        </div>
                    </div>

                    <div class="w-full lg:flex lg:w-1/2 lg:justify-end">
                        <ul class="hidden whitespace-nowrap lg:flex flex-col items-center font-medium lg:flex-row space-x-4 xl:space-x-8">
                            <li
                                v-if="!isSearchInputVisible"
                                class="flex"
                            >
                                <Link
                                    :href="state.links?.['about-us']?.[locale.value] ? state.links?.['about-us']?.[locale.value] : '/about'"
                                    class="text-xxs text-black uppercase font-semibold hover:text-green"
                                >
                                    {{ state.links?.['about-us']?.title?.[locale.value] ? state.links?.['about-us']?.title?.[locale.value] : $t('titleAbout') }}
                                </Link>
                            </li>
                            <li
                                v-if="!isSearchInputVisible"
                                class="flex"
                            >
                                <Link
                                    :href="state.links?.partnership?.[locale.value] ? state.links?.partnership?.[locale.value] : '/partnership'"
                                    class="text-xxs text-black uppercase font-semibold hover:text-green"
                                >
                                    {{ state.links?.partnership?.title?.[locale.value] ? state.links?.partnership?.title?.[locale.value] : $t('titlePartnership') }}
                                </Link>
                            </li>
                            <li
                                v-if="!isSearchInputVisible"
                                class="flex"
                            >
                                <Link
                                    :href="state.links?.contact?.[locale.value] ? state.links?.contact?.[locale.value] : '/contacts'"
                                    class="text-xxs text-black uppercase font-semibold hover:text-green"
                                >
                                    {{ state.links?.contact?.title?.[locale.value] ? state.links?.contact?.title?.[locale.value] : $t('titleContacts') }}
                                </Link>
                            </li>
                        </ul>

                        <div
                            v-if="!isSearchInputVisible"
                            class="hidden lg:block whitespace-nowrap my-auto ml-4 mr-2 xl:mx-8"
                        >
                            <ButtonCmp kind="small">
                                <a :href="`tel:${state?.settings?.main_phone_number}`">
                                    {{ state?.settings?.main_phone_number }}
                                </a>
                            </ButtonCmp>
                        </div>

                        <div
                            v-if="!isSearchInputVisible"
                            class="hidden lg:flex items-center mr-4 lg:mr-5"
                        >
                            <Basket />
                        </div>

                        <!-- Search
                            :is-visible="isSearchInputVisible"
                            class="mt-5 lg:mt-0 w-full"
                            :class="{'hidden lg:block': !burgerMenuIsOpen}"
                            @toggle-search="toggleSearchInput"
                        / -->

                        <ul
                            v-show="burgerMenuIsOpen"
                            id="accordion-collapse"
                            class="my-5 lg:hidden"
                            data-accordion="collapse"
                        >
                            <template v-for="(burgerItem, index) in doorMegaMenuItems">
                                <li>
                                    <div :id="`accordion-open-heading-${index}`">
                                        <button
                                            type="button"
                                            class="
                                                flex items-center justify-between w-full
                                                 font-medium text-sm rtl:text-right text-black-500
                                                border-b border-green bg-white py-5 px-2
                                            "
                                            aria-expanded="false"
                                            :data-accordion-target="`#accordion-collapse-body-${index}`"
                                            :aria-controls="`accordion-collapse-body-${index}`"
                                        >
                                            <span class="text-black">{{ burgerItem?.linkSrc?.title?.[locale.value] }}</span>
                                            <svg
                                                data-accordion-icon
                                                aria-hidden="true"
                                                class="rotate-180 transition-transform duration-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="9"
                                                height="6"
                                                viewBox="0 0 9 6"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 4.75C1.4 4.35 3.83333 2.25 5 1.25L8.5 4.75"
                                                    stroke="black"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div
                                        :id="`accordion-collapse-body-${index}`"
                                        :aria-labelledby="`accordion-collapse-heading-${index}`"
                                        class="hidden"
                                    >
                                        <div class="my-3">
                                            <template v-for="item in burgerItem?.linkSrc?.blocks?.[locale.value]">
                                                <a
                                                    :href="burgerItem.linkSrc?.[locale.value] + `/#${item?.id}`"
                                                    class="
                                                        text-black-dark hover:text-green  font-normal text-xs
                                                        block w-fit py-2 px-6
                                                    "
                                                    @click.prevent="
                                                        onNavigateTo(burgerItem.linkSrc?.[locale.value], item?.id)
                                                    "
                                                >
                                                    {{ item?.title }}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </li>
                            </template>

                            <template v-for="(burgerItem, index) in partitionsMegaMenuItems">
                                <li>
                                    <div :id="`accordion-open-heading-partition${index}`">
                                        <button
                                            type="button"
                                            class="
                                                flex items-center justify-between w-full
                                                font-medium text-sm rtl:text-right text-black-500
                                                border-b border-green bg-white py-5 px-2
                                            "
                                            aria-expanded="false"
                                            :data-accordion-target="`#accordion-collapse-body-partition${index}`"
                                            :aria-controls="`accordion-collapse-body-partition${index}`"
                                        >
                                            <span class="text-black">{{ burgerItem?.linkSrc?.title?.[locale.value] }}</span>
                                            <svg
                                                data-accordion-icon
                                                aria-hidden="true"
                                                class="rotate-180 transition-transform duration-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="9"
                                                height="6"
                                                viewBox="0 0 9 6"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 4.75C1.4 4.35 3.83333 2.25 5 1.25L8.5 4.75"
                                                    stroke="black"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div
                                        :id="`accordion-collapse-body-partition${index}`"
                                        :aria-labelledby="`accordion-collapse-heading-partition${index}`"
                                        class="hidden"
                                    >
                                        <div class="my-3">
                                            <template v-for="item in burgerItem?.linkSrc?.blocks?.[locale.value]">
                                                <a
                                                    :href="burgerItem.linkSrc?.[locale.value] + `/#${item?.id}`"
                                                    class="text-black-dark hover:text-green  font-normal text-xs block w-fit py-2 px-6"
                                                    @click.prevent="onNavigateTo(burgerItem.linkSrc?.[locale.value], item?.id)"
                                                >
                                                  {{ item?.title }}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </li>
                            </template>
                            <li v-if="state?.links?.['about-us']?.blocks?.[locale.value]?.length">
                                <div id="accordion-open-heading-about">
                                    <button
                                        type="button"
                                        class="
                                            flex items-center justify-between w-full
                                             font-medium text-sm rtl:text-right text-black-500
                                            border-b border-green bg-white py-5 px-2
                                        "
                                        aria-expanded="false"
                                        data-accordion-target="#accordion-collapse-body-about"
                                        aria-controls="accordion-collapse-body-about"
                                    >
                                        <span class="text-black">
                                            {{ state?.links?.['about-us']?.title?.[locale.value] || $t('titleAbout') }}
                                        </span>
                                        <svg
                                            data-accordion-icon
                                            aria-hidden="true"
                                            class="rotate-180 transition-transform duration-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="6"
                                            viewBox="0 0 9 6"
                                            fill="none"
                                        >
                                            <path
                                                d="M1 4.75C1.4 4.35 3.83333 2.25 5 1.25L8.5 4.75"
                                                stroke="black"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div
                                    id="accordion-collapse-body-about"
                                    aria-labelledby="accordion-collapse-heading-about"
                                    class="hidden"
                                >
                                    <div class="my-3">
                                        <template v-for="item in state?.links?.['about-us']?.blocks?.[locale.value]">
                                            <a
                                                :href="state?.links?.['about-us']?.[locale.value] + `/#${item?.id}`"
                                                class="
                                                    text-black-dark hover:text-green  font-normal text-xs
                                                    block w-fit py-2 px-6
                                                "
                                                @click.prevent="
                                                    onNavigateTo(state?.links?.['about-us']?.[locale.value], item?.id)
                                                "
                                            >
                                                {{ item?.title }}
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Link
                                    :href="state?.links?.['partnership']?.[locale.value] || '/partnership'"
                                    type="button"
                                    class="
                                        flex items-center justify-between w-full
                                         font-medium text-sm rtl:text-right text-black-500
                                        border-b border-green bg-white py-5 px-2
                                    "
                                    @click="toggleBurgerMenu"
                                >
                                    <span class="text-black">
                                        {{ state?.links?.['partnership']?.title?.[locale.value] || $t('titlePartnership') }}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="state?.links?.['contact']?.[locale.value] || '/contacts'"
                                    type="button"
                                    class="
                                        flex items-center justify-between w-full
                                         font-medium text-sm rtl:text-right text-black-500
                                        border-b border-green bg-white py-5 px-2
                                    "
                                    @click="toggleBurgerMenu"
                                >
                                    <span class="text-black">
                                        {{ state?.links?.['contact']?.title?.[locale.value] || $t('titleContacts') }}
                                    </span>
                                </Link>
                            </li>
                            <!-- <li class="px-2 py-5 border-b border-green">Скляні міжкімнатні перегородки</li>
                            <li class="px-2 py-5 border-b border-green">Скляні перегородки для ванних кімнат</li> -->
                        </ul>

                        <div
                            v-if="!isSearchInputVisible"
                            class="w-full relative lg:w-auto lg:my-auto ml-4 xl:ml-8"
                            :class="{ 'lg:block': !isSearchInputVisible }"
                        >
                            <button
                                class="hidden lg:flex text-xxs text-black md:uppercase hover:text-green items-center justify-between w-full py-2 px-3 font-medium md:w-auto md:p-0"
                                @click="toggleLanguageBtn"
                            >
                                {{ languages[language.chosen] }}
                                <svg
                                    class="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>
                            <button
                                v-show="isLanguageVisible"
                                ref="languageBtnRef"
                                class="hidden lg:block bg-white absolute uppercase top-5 left-5 text-xxs shadow-light rounded-full"
                                @click="toggleLanguageBtn"
                            >
                                <Link
                                    :href="$page.props.localizedUrls?.[available]"
                                    :hreflang="available"
                                    :lang="available"
                                    rel="alternate"
                                    class="hover:text-green flex justify-center items-center w-10 h-10"
                                >
                                    {{ languages[available] }}
                                </Link>
                            </button>
                        </div>

                        <div
                            v-show="burgerMenuIsOpen"
                            class="text-black w-full text-left my-10 ml-2 font-medium text-sm lg:hidden"
                        >
                            <Link
                                :href="$page.props.localizedUrls?.uk"
                                hreflang="uk"
                                lang="uk"
                                rel="alternate"
                                class="hover:text-green"
                                :class="{ 'text-green': language.chosen === 'uk' }"
                                @click="toggleBurgerMenu"
                            >
                                УКР
                            </Link>
                            <span class="px-2"> &#47; </span>
                            <Link
                                :href="$page.props.localizedUrls?.ru"
                                hreflang="ru"
                                lang="ru"
                                rel="alternate"
                                class="hover:text-green"
                                :class="{ 'text-green': language.chosen === 'ru' }"
                                @click="toggleBurgerMenu"
                            >
                                РУС
                            </Link>
                        </div>
                    </div>
                </div>

                <ButtonsGroup
                    v-show="burgerMenuIsOpen"
                    kind="round-small"
                    class="lg:hidden pb-5"
                    :close-burger="toggleBurgerMenu"
                />
            </div>
            <Transition
                enter-active-class="transition ease-out duration-500"
                enter-from-class="opacity-0 transform -translate-y-2"
                enter-to-class="opacity-100 transform translate-y-0"
                leave-active-class="transition ease-in duration-200"
                leave-from-class="opacity-100 transform translate-y-0"
                leave-to-class="opacity-0 transform -translate-y-2"
            >
                <MegaMenu
                    v-if="isMegaMenuOpen"
                    ref="megaMenuRef"
                    :menu-items="currentMenuItems"
                    class="hidden lg:block"
                />
            </Transition>
        </nav>
    </header>
</template>

<style scoped>
.header-padding {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}
</style>
